/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

::selection {
  background: #ffcc00;
  color: #161825;
}

:root {
  --foreground: #f8fafc;
  --background: #161825;
  --primary: #FFCC00;
  --secondary: #ff4f66;
  --black: #121314;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600&display=swap');

@font-face {
  font-family: "SF Mono";
  font-weight: 400;
  font-style: normal;
  src: url("./assets/fonts/SFMono/SFMono-Regular.woff") format("woff");
}

@font-face {
  font-family: "SF Mono";
  font-weight: 700;
  font-style: normal;
  src: url("./assets/fonts/SFMono/SFMono-Semibold.woff") format("woff");
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  max-width: 100vw;
  min-height: 100vh;
  font-size: calc(1.6rem + 0.33vw);
  font-weight: 300;
  background: var(--background);
  color: var(--foreground);
  overflow-x: hidden;
  padding: 0 16px;
}

/* body.dark {
  background: var(--foreground);
  color: var(--background);
} */


a {
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  font-weight: 700;
  color: inherit;
  transition: all 200ms ease-in-out;
}

#root {
  max-width: 640px;
  margin: 0 auto;
}

@media screen and (min-width: 640px) {
  body {
    padding: 6px 16px 0 16px;
  }
}
