header {
  text-align: center;
}

h3 {
  font-weight: 400;
}

.mono {
  display: block;
  font-size: 1.4rem;
  margin: 2px 0;
}

.avatar {
  width: 228px;
  height: 305px;
  object-fit: cover;
  /* border-radius: 10px; */
  margin: 8px 0;
}

header p {
  text-align: initial;
  margin: 18px 0;
}

header p span {
  font-weight: 600;
  background: linear-gradient(to right, #ffcc00, #ff4f66);
  background-size: 200% 200%;
  background-position: right;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

@media screen and (min-width: 640px) {
  header {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-areas:
      "avatar h3"
      "avatar mono"
      "avatar paragraph-one"
      "avatar  paragraph-two"
      "avatar paragraph-three";
    grid-template-rows: 1fr;
    grid-template-columns: 220px 1fr;
  }

  .avatar {
    grid-area: avatar;
    width: 190px;
    height: 253px;
  }
  h3 {
    grid-area: h3;
    text-align: initial;
  }
  h3 br {
    display: none;
  }
  .mono {
    grid-area: mono;
    text-align: initial;
  }
  .paragraphOne {
    grid-area: paragraph-one;
  }
  .paragraphTwo {
    grid-area: paragraph-two;
  }
  .paragraphThree {
    grid-area: paragraph-three;
  }

  header p {
    margin: 10px 0;
  }
}
