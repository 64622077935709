.container {
  width: 160px;
  height: 190px;
  padding: 6px 16px;
  font-size: 1.6rem;
  border-radius: 5px;
  box-shadow: 0 0 3px 1px rgba(156, 163, 175, 0.3);
  /* margin-bottom: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.title {
  background: linear-gradient(to right, #ffcc00, #ff4f66);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.container span svg {
  width: 22px;
  height: 22px;
}

.container h4 {
  margin: 6px 0;
}

.container span {
    align-self: flex-end;
}

@media screen and (min-width: 640px) {
  .container {
    width: 200px;
  }
}