h3 {
  margin: 32px 0 9px 0;
}

main p {
  margin-bottom: 16px;
}

.tags {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px;
}

.tags :nth-child(odd) {
  background-color: #ffcc00;
  padding: 1px 3px;
  border-radius: 5px;
  color: #161825;
  font-weight: 400;
  font-size: 1.6rem;
}

.tags :nth-child(even) {
  background-color: #ff4f66;
  padding: 1px 3px;
  border-radius: 5px;
  color: #161825;
  font-weight: 400;
  font-size: 1.6rem;
}

section {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 320px));
  gap: 20px;
  justify-content: center;
}

article {
  width: 320px;
  height: 186px;
  padding: 16px;
  font-size: 1.6rem;
  box-shadow: 0 0 3px 1px rgba(156, 163, 175, 0.3);
  border-radius: 5px;
}

article h4 {
  margin-bottom: 6px;
  background: linear-gradient(to right, #ff4f66, #ffcc00);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

article ul {
  margin-left: 6px;
}

article ul li {
  margin-bottom: 3px;
  font-weight: 600;
}

.Projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}

main .contact {
  width: 100%;
  height: auto;
  margin-top: 32px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.email {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.6rem;
  color: transparent;
  background: linear-gradient(-45deg, #e11d48, #fb923c, #facc15, #be123c);
  background-size: 400% 400%;
  background-clip: text;
  -webkit-background-clip: text;
  animation: gradient 15s ease infinite;
}

.social {
  width: 200px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social a {
  transition: transform 200ms ease-in-out;
}

.social a:hover {
  transform: translateY(-5px);
}

h5 {
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

h5 svg {
  width: 16px;
}

@media screen and (min-width: 640px) {
  section {
    grid-template-columns: repeat(auto-fit, minmax(190px, 200px));
  }

  article {
    width: 200px;
  }

  main .contact {
    text-align: center;
  }
}
